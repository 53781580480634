import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'
import $ from 'jquery';
import "./Hero.scss";

class Hero extends Component {

    componentDidMount() {
        var delay = 3000;
            var pause = 1000;
            var tap = 200;
            var el = $(".again"), all = $(".text, .content, .more, .again");
            // el.dblclick(function(){ $(this).attr("contenteditable", "true"); $(this).focus(); all.removeClass("visible"); $(this).attr("active", "true") });
            el.focus(function(){all.removeClass("visible"); $(this).attr("active", "true"); });
            el.click(function(){ if ($(this).is(":focus")){} else { all.addClass("visible"); $(this).attr("selected", "true")} });
            $(document).click(function(event) { 
                if(!$(event.target).closest('.again').length) {
                    if($(".again").attr("active")=== "true") {
                        all.addClass("visible"); $(".again").attr("active", "false"); $(".again").removeAttr("selected"); 
                    } else {
                        all.removeClass("visible"); $(".again").attr("active", "false"); $(".again").removeAttr("selected"); 
                    }
                }        
            });
            
            el.blur(function(){ console.log("blur");$(this).attr("contenteditable", "false"); all.addClass("visible");  });
            setTimeout(function(){
                all.addClass("visible");
            }, delay)
            setTimeout(function(){
                $(".text").css("transform", "none");
                $(".text").css("transform", "translateX(-0.79%)");
            }, delay + pause + tap)
            setTimeout(function(){
                $(".text").css("transform", "translateX(-1.6%)");
            }, delay + pause + tap*2)
            setTimeout(function(){
                $(".text").css("transform", "translateX(-2.45%)");
            }, delay + pause +  tap*3)
            
            setTimeout(function(){
                    all.removeClass("visible");
            }, delay + pause +  tap*6 + pause*2);
            
            $(".text").attr("data-offset-y", 0);
            $(".text").attr("data-offset-x", 0);
            
            
            // $('.text').on('mousedown', function (e) {
            
            //     if ($(".again").attr("active") === "true") {
            //         return false;
            //     }
                
            //     console.log("hai");

            //     $(this).addClass('dragging');
            //     var s;

            //     var startX = e.pageX;
            //     var startY = e.pageY;
                
            //     var offsetY = Number($(".text").attr("data-offset-y"))
            //     var offsetX = Number($(".text").attr("data-offset-x"))
                
            //     $(this).parents(".intro-banner").on('mousemove', function (e) {
            //         if ($(".text").hasClass("dragging")) {
    
            //             var offY = e.pageY - startY;
            //             $(".text").attr("data-offset-y", offsetY+offY);
            //             var offX = e.pageX - startX; 
            //             $(".text").attr("data-offset-x", offsetX+offX);
            //             var cx = offsetX+offX;
            //             var cy = offsetY+offY;
            //             s =  "translateY(" + cy + "px) translateX("  + cx + "px)";
            //             $('.text').css("transform", s  );
                        
            //         }

            //         })
            //         $(".text").on('mouseup', function () {

            //             $(this).removeClass('dragging');

            //         });

            //     return false;    
            // });
    }

  render() {
    return (
        <div className="intro-banner">
          <div className="intro-banner-content">
            <motion.div 
              initial={{ opacity: 0, y: 90}}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                ease: [0, 0.55, 0.45, 1],
                duration: .9,
                delay: .8,
                opacity: {
                  duration: .45
                }
              }}
              className="again greeting"
            >Vincent Snagg</motion.div>
            <motion.h3 
              initial={{ opacity: 0, y: 110}}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                ease: [0, 0.55, 0.45, 1],
                duration: .9,
                delay: .82,
                opacity: {
                  duration: .45
                }
              }}
            className="sub-greeting">
              I'm a UX designer who's passionate about crafting intuitive experiences through prototyping.
            </motion.h3>
            <motion.div
              initial={{ opacity: 0, y: 130}}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                ease: [0, 0.55, 0.45, 1],
                duration: .9,
                delay: .84,
                opacity: {
                  duration: .45
                }
              }}
            >
              <Link to="/about">
                More about me
              </Link>
            </motion.div>
          </div>
        </div>
    );
  }
}

export default Hero;
