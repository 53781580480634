import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "featherlight/release/featherlight.min.js";
import "featherlight/release/featherlight.min.css";
import "./Post.scss";
import Password from "./Password";
import "./Password.scss";
import utils from '../utilities';
import _ from 'underscore';

class Post extends Component {
  state = {
    slug: this.props.match.params.slug,
    currentPost: {},
    postFeaturedMedia: {},
    postHTML: "",
    postTitle: "",
    postProtected: false,
    showPasswordScreen: false,
    revealContent: false,
    backLinkPinned: false,
    backLinkAnimating: false,
    showbackLink: false,
    headerGradientOff: false
  };

  initLightbox = () => {
    
      let pc = document.querySelector('.project-container');
      let imgs = null;
      if (pc) {
        imgs = pc.querySelectorAll('img');
      }

      console.log(pc, imgs);

      if (imgs && pc) {
        imgs.forEach((img, index) => {

          const imgSrc = img.getAttribute("src");
          const imgParent = img.parentElement;
          const imageLink = document.createElement("a");
  
          img.classList.add(`image-${index}`);
          imageLink.setAttribute("href", imgSrc);
          imageLink.setAttribute("data-featherlight", `.image-${index}`);
          imageLink.appendChild(img);
          imgParent.appendChild(imageLink);
  
        });
    }
  }

  componentDidMount() {
    const url = `https://nport.vincentsnaggdesign.com/wp-json/wp/v2/posts?slug=${this.state.slug}`;
    fetch(url)
      .then(res => res.json())
      .then(slug_post => {

        fetch(`https://nport.vincentsnaggdesign.com/wp-json/wp/v2/posts/${slug_post[0].id}/?_embed`)
        .then(res => res.json())
        .then(post => {
          const currentPost = post;
          console.log(currentPost);

          this.setState({ currentPost });
          // Capture the Title from the Post
          this.setState({ postTitle: currentPost.title.rendered });
          // Capture the HTML from the Post
          this.setState({ postHTML: currentPost.content.rendered });
          // Turn Off Gradient
          this.setState({ headerGradientOff: currentPost.acf.header_gradient_off });
          // Set Featured Image
          this.setState({
            postFeaturedMedia: currentPost._embedded["wp:featuredmedia"]["0"]
          });
  
          if (currentPost.content.protected) {
            // Check for password protected content
            this.setState({ showPasswordScreen: true });
          } else {
            // Otherwise reveal content
            this.setState({revealContent: true});
            // Initialize lightbox on images
            setTimeout(() => {
              this.initLightbox();
            }, 500);
          }
  
          // Pinning the "back to projects" link on scroll
          if (utils.getDocWidth() > 1100) {
            document.addEventListener("scroll", _.throttle( () => {
              if (window.scrollY > 500 && window.scrollY < document.documentElement.scrollHeight - document.documentElement.clientHeight - 300) {
                this.toggleBackLink(true);
              } else {
                this.toggleBackLink(false);
              }
            }, 100))
          }
        })
      })
      .catch(err => console.log(err));
  }

  createPost(rawHTML) {
    return { __html: rawHTML };
  }

  updatePostHTML = postHTML => {
    this.setState({ postHTML });
  };

  removePasswordScreen() {
    this.setState({ showPasswordScreen: false });
    this.setState({revealContent: true})

    // Initialize lightbox on images
    setTimeout(() => {
      this.initLightbox();
    }, 500);
  }

  toggleBackLink = show => {

    const ctxt = this;

    if (show) {

      if (!this.state.backLinkAnimating) {

        const setLinkPos = () => {
          return new Promise(function (resolve, reject) {
            ctxt.setState({ backLinkPinned : true })
            ctxt.setState({backLinkAnimating: true});
            resolve();
          });
        }
  
        const animText = () => {
          return new Promise(function (resolve, reject) {
            ctxt.setState({ showbackLink : true })
            setTimeout(() => {
              ctxt.setState({backLinkAnimating: false});

              // check if needs to be updated after animation + scroll finishes
              if (window.scrollY > 500 && window.scrollY < document.documentElement.scrollHeight - document.documentElement.clientHeight - 500) {

              } else {
                ctxt.toggleBackLink(false);
              }

              resolve();
            }, 600);
          });
        }
  
        setLinkPos().then(animText);
      }

    } else {

      if (!this.state.backLinkAnimating) {
        const animTextOut = () => {
          return new Promise(function (resolve, reject) {
            ctxt.setState({backLinkAnimating: true});
            ctxt.setState({ showbackLink : false });
            setTimeout(() => { resolve(); }, 600); //only resolve after duration of the CSS transition
          });
        }
  
        const setLinkPosOut = () => {
          return new Promise(function (resolve, reject) {
            ctxt.setState({backLinkAnimating: false});
            ctxt.setState({ backLinkPinned : false });

            // check if needs to be updated after animation + scroll finishes
            if (window.scrollY > 500 && window.scrollY < document.documentElement.scrollHeight - document.documentElement.clientHeight - 500) {
              ctxt.toggleBackLink(true);
            } else {

            }
            resolve();
          });
        }
  
        animTextOut().then(setLinkPosOut);
      }
    }
  }

  render() {

    return (
      <React.Fragment>
        <CSSTransition
          in={this.state.revealContent}
          timeout={1000}
          classNames="main-content-trans"
          unmountOnExit
        >
          <React.Fragment>
            <h1 className="project-title">{this.state.postTitle}</h1>
            <div className={ this.state.headerGradientOff ? "project-hero no-gradient" : "project-hero" }>
              { this.state.postFeaturedMedia.source_url &&
                <img
                  className="project-hero-img"
                  src={this.state.postFeaturedMedia.source_url}
                  alt={this.state.postFeaturedMedia.alt_text}
                />
              }
              <h1 className="project-title-hero">
                <span className="project-title-hero-text">
                  {this.state.postTitle}
                </span>
              </h1>
            </div>
            <div className={ this.state.backLinkPinned ? "project-back-link-container-fixed pinned" : "project-back-link-container-fixed" }>     
            { this.props.location.pathname.includes("/projects/") ? 
              (
                <Link className={this.state.showbackLink ? "project-back-link show": "project-back-link"} to="/">
                  {" "}
                  ⟵ &nbsp;&nbsp; Back to All Projects{" "}
                </Link>
              )
              :
              (
                <Link className={this.state.showbackLink ? "project-back-link show": "project-back-link"} to="/experiments">
                  {" "}
                  ⟵ &nbsp;&nbsp; Back to All Experiments{" "}
                </Link>
              )
            }
            </div> 
            <div
              className="project-container"
              dangerouslySetInnerHTML={this.createPost(this.state.postHTML)}
            />        
            { this.props.location.pathname.includes("/projects/") ? 
              (
                <div className="project-back-link-container-default">
                  <Link className="project-back-link" to="/">
                    {" "}
                    Back to All Projects{" "}
                  </Link>
                </div>
              )
              :
              (
                <div className="project-back-link-container-default">
                  <Link className="project-back-link" to="/experiments">
                    {" "}
                    Back to All Experiments{" "}
                  </Link>
                </div>
              )
            }
          </React.Fragment>
        </CSSTransition>

        <CSSTransition
          in={this.state.showPasswordScreen}
          timeout={1000}
          classNames="password-trans"
          unmountOnExit
        >
          <Password
            post={this.state.currentPost}
            updatePostHTML={this.updatePostHTML}
            removePasswordScreen={() => {
              this.removePasswordScreen();
            }}
          />
        </CSSTransition>

      </React.Fragment>
    )
  }
}

export default Post;
