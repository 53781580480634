import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './About.scss';

class About extends Component {
  render() {
    return (
      <div>
        <div className="project-container about-content">    
          <div className="about-pic">
            <img alt="" src="/images/me.jpg" />
          </div>

          <h2 className="emphasis">I'm Vincent, a product designer based in NYC.</h2>

          <p>Currently I work on modernizing and unifying payment experiences at Amazon. I also worked on improving the Amazon Search experience on desktop. Previously, I worked at IBM, humanizing complex enterprise applications and building out a healthcare design system. In 2016, I graduated from the University of Pennsylvania with a dual degree in Fine Arts and in Visual Studies.</p>

          <p>My first passion was graphic design, but over time I have become fascinated with the interactivity of digital web and mobile experiences. I'm always looking for opportunities to influence or communicate via coded prototypes that others can interact with — that feel like the real thing. Because of this, I love learning new frontend techniques and technologies that allow me to better communicate ideas with motion and interactivity.</p>

          <p>Don't hesitate to get in touch at vincent.snagg[at]gmail.com.</p>

          <p><Link to="/">⟵ &nbsp; Back</Link></p>

        </div>
      </div>
    )
  }
}

export default About
