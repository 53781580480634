import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navigation from './components/Navigation';
import Experiments from './components/Experiments';
import About from './components/About';
import Hero from './components/Hero';
import Post from './components/Post';
import Password from './components/Password';
import Footer from './components/Footer';
// import ScrollToTop from './components/ScrollToTop';
import './App.scss';

class App extends Component {

  state = {
    posts: [],
    isAuthenticated: false,
    imgLoaded: false,
    needsAuthetication: false,
    currentProtectedRoute: '',
    skeletonPostsInit: [1,2,3,4,5,6]
  }

  componentDidMount() {
    // fetch posts by the "homepage" category
    fetch("https://nport.vincentsnaggdesign.com/wp-json/wp/v2/posts?categories=5")
    .then(res => res.json())
    .then(homepage_posts => {
      // for each post get the embeddable version with images
      for (const post in homepage_posts) {
        fetch(`https://nport.vincentsnaggdesign.com/wp-json/wp/v2/posts/${homepage_posts[post].id}/?_embed`)
        .then(res => res.json())
        .then(post => {
          // make a copy of the posts array in state
          let posts_array = this.state.posts;
          // add the new post to the list
          posts_array.push(post);
          // sort the list of posts by the post's date property to get a predictable order
          posts_array.sort((post1, post2) => {
            const d1r = new Date(post1.date);
            const d1 = d1r.getTime();
            const d2r = new Date(post2.date);
            const d2 = d2r.getTime();
            return (d2 > d1) ? 1 : -1;
          })
          // set the state to update the posts array
          this.setState({posts: posts_array})
        });
      }
    })
    .catch(err => console.log(err))
  }

  // skeleton loading
  handleLoad = () => {
    this.setState({imgLoaded: true});
  }

  needsAuthentication = (url) => { 
    this.setState({ needsAuthentication: true })
    this.setState({ currentProtectedRoute: url})
  }

  render() {
    console.log(this.state.posts)
    return (
      <div className="App">
        <Router>
          <div className="App-content">
          <Navigation />
          <Switch>
          <Route exact path="/" render={() => (
            <React.Fragment>
              {/* INTRO SECTION */}
              <Hero {...this.state} />
              {/* PROJECT SECTION */}             
              <div id="projects" className="project-container home-project-container">
                { this.state.posts.length > 1 ? this.state.posts
                .filter( post => {
                  return post.categories.includes(5);
                })
                .map( post => {
                  return (
                    (<motion.article className="project" key={post.id}
                        initial={{y: 200, opacity: 0}}
                        whileInView={{y: 0, opacity: 1}}
                        viewport={{ once: true }}
                        transition={{
                          duration: 2,
                          ease: [0.16, 1, 0.3, 1]
                        }}
                    >
                      {/* Project Details Column */}
                      <motion.div className="project-details-container"
                      >
                        {/* Project Header */}
                        <h2 className="home-project-title" title={post.title.rendered}>
                          <Link to={{
                            pathname: `/projects/${post.slug}`,
                          }}>
                            { this.state.imgLoaded ? 
                              post.title.rendered : 
                              (<div class="skeleton"></div>)
                            }
                          </Link>
                        </h2>

                      {/* Project Metadata (Year / Company)*/}
                      <div className='project-metadata'>
                        { this.state.imgLoaded ? 
                              `${post.acf.project_year} — ${post.acf.project_company}` : 
                              (<div class="skeleton"></div>)
                        }
                      </div>

                      {/* Project Link */}
                      <div className='project-link-container'>
                          <div className='project-link'>
                            <Link className="project-to-link" to={{
                              pathname: `/projects/${post.slug}`,
                            }}>
                                { this.state.imgLoaded ? 
                                  "View project →" : 
                                  (<div class="skeleton"></div>)
                                }
                            </Link>
                          </div>
                        </div>
                      </motion.div>

                      {/* Project Image */}
                      <div className={ this.state.imgLoaded ?
                             "project-image-container" : 
                             "project-image-container skeleton"
                          }>
                        <Link to={{
                          pathname: `/projects/${post.slug}`,
                        }}>
                            <img 
                              alt="" 
                              src={ post.acf.home_img ? post.acf.home_img : post._embedded['wp:featuredmedia']['0'].source_url} 
                              // remove skeleton, show content
                              onLoad={this.handleLoad}
                            /> 
                        </Link>
                      </div>
                    </motion.article>)
                  )
                }): 
                this.state.skeletonPostsInit.map( num => (
                  <article className="project">
                    <h2 className="home-project-title">
                      <Link to={{
                        pathname: ``,
                      }}>
                        <div class="skeleton"></div>
                      </Link>
                    </h2>
                    <div className="project-image-container skeleton">
                      <Link to={{
                        pathname: ``,
                      }}>
                      </Link>
                    </div>
                    {/* <Link className="project-to-link" to={{
                        pathname: ``,
                      }}>
                          <div class="skeleton"></div>
                    </Link> */}
                  </article>
                ))
                }
              </div>
          </React.Fragment>
          )} />
          <Route exact path="/about" render={(routeProps) => {
            return (<About {...this.state} {...routeProps} />)
          }} />
          <Route exact path="/experiments" render={(routeProps) => {
            return (<Experiments {...this.state} {...routeProps} />)
          }} />
          <Route path="/projects/:slug" render={(routeProps) => {
            return (<Post {...this.state} {...routeProps} />)
          }}/>
          <Route path="/experiments/:slug" render={(routeProps) => {
            return (<Post {...this.state} {...routeProps} />)
          }}/>
          <Route path="/auth" component={Password} />
          </Switch>
          </div>
          <Footer />
          
        </Router>
      </div>
    )
  }
}

export default App
