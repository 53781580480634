import React, { Component } from 'react';
import './Password.scss'

class Password extends Component {

    state = { 
        inputText: '',
        incorrectlyEntered: false,
        submitAvailable: false
    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.checkPassword(this.state.inputText)
        }
    }

    handleChange = (event) => {
        this.setState({inputText: event.target.value});
        if (event.target.value.length > 0) {
            this.setState({ submitAvailable: true })
        } else {
            this.setState({ submitAvailable: false })
        }
    }

    checkPassword = (text) => {

        const ctxt = this;
        const url = `https://nport.vincentsnaggdesign.com/wp-json/wp/v2/posts/${this.props.post.id}?password=${text}`;

        fetch(url)
        .then(res => res.json())
        .then(data => {
            if (data.hasOwnProperty('content') && data.content.rendered.length > 0) {
                console.log("success");
                ctxt.removeScreen(data.content.rendered);
                this.setState({ incorrectlyEntered: false });
            }
             if (data.hasOwnProperty('data') && data.data.status === 403) {
                this.setState({ incorrectlyEntered: true });
             }
        })
        .then(data => console.log(data));
    }

    removeScreen = (content) => {
        this.props.removePasswordScreen();
        this.props.updatePostHTML(content);
    }

    componentDidMount() {
        var pEl = document.querySelector('.password-input');
        pEl.focus();
    }

    render() {
        return (
            <div>
            <div className="password-wrapper">
                <div className="password-container">
                    <div className="lock-image">
                        <img alt="" src="/images/lock.svg" />
                    </div>
                    <div className={this.state.incorrectlyEntered ? "password-error password-input-container" : "password-input-container"}>
                        <input className="password-input" type="password" value={this.state.inputText} onChange={this.handleChange} placeholder="Enter password" onKeyPress={this.handleKeyPress} />
                        <button className={ this.state.submitAvailable ? "submit-available password-submit" : "password-submit"} onClick={() => { this.checkPassword(this.state.inputText) }}>
                            <img alt="Submit" src="/images/arrow.svg" />
                        </button>
                    </div>
                    { this.state.incorrectlyEntered && 
                        <div className="incorrect-text"> The password was incorrect </div>
                    }
                </div>
            </div>
            </div>
        )
    }
}

export default Password
